
/*Defaul Styling */
.roundi {
    border-radius: 50%;
    border-radius: 50%;
    width: 90px; 
    height: 90px; 
    display: block; 
    margin: 20px auto; 
    border: 2px solid #B09A69;
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 50%; */
}

.force-w{
    max-width:100%;
    margin-left: auto;
    margin-right: auto;
}

.schedule-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 70%;
    background-color: #EBD096;
    border: 2px solid #B09A69;
    border-radius: 15px; 
    padding: 20px;
    margin: auto;
  }

.schedule-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.schedule-col-item {
  padding-bottom: 6%;
}

#address-heading{
    text-align: center;
}

/* Media Query for mobile devices (man-width: 767px) */
@media (max-width: 767px) {
    th{
        display: none;
    }
    
    th, td {
        font-size: 0.7rem; 
        padding: 4px; 
    }

    table {
        width: 100%;
        font-size: 0.9rem; 
        display: block; 
        
    }
}

/* Media Query for tablets (min-width: 768px) */
@media (min-width: 768px) {
    .roundi {
        width: 200px; 
        height: 200px; 
        margin: 30px auto; 
    }

    .flex-container {
        flex-direction: row; 
        flex-wrap: wrap; 
        justify-content: space-between; 
    }

    .aboutSummary-info{
        min-width: 423px;
        min-height: 510px;
    }

}

/* Media Query for desktop (min-width: 1024px) */
@media (min-width: 1024px) {
    .roundi {
        width: 250px; 
        height: 250px; 
        margin: 50px auto; 
    }

    .flex-container {
        flex-direction: row; 
        justify-content: space-between; 
    }

}